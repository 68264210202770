import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Questionnaire from './Questionnaire';
import { questions } from '../assets/questions';
import { useUser } from '../hooks/useUser';
import { CircularProgress } from '@mui/material';
import LoadingPage from './LoadingPage';

const Survey = () => {
    const { user, isLoading } = useUser();
    const navigate = useNavigate();

    // Remove the name question if the user is already registered
    const questionsFiltered = (user?.name) ? questions.filter(question => question.name !== 'name') : questions;

    const handleFinish = (userRole) => {
        const queryString = user?.id ? `?mid=${user.id}` : '';
        const slug = userRole ?? "movilizar-general"

        if (!userRole) {
            console.error('No role to redirect to.');
        }

        window.location.href = `https://movilicemos.org/${slug}${queryString}`;
        navigate('/loading');
    }

    if (isLoading) {
        return <CircularProgress sx={{ my: 10 }} data-testid="loading-spinner" />;
    }

    console.log('user', user, questionsFiltered);
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Questionnaire
                        questions={questionsFiltered}
                        userName={user?.name}
                        handleFinish={handleFinish}
                        userId={user?.id}
                    />
                }
            />
            <Route path="/loading" element={<LoadingPage />} />
        </Routes>
    );

};

export default Survey;
