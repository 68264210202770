export const getFormValues = () => {
    const formValues = {};
    const form = document.querySelector('form');
    const formData = new FormData(form);
    for (let [name, value] of formData.entries()) {
        formValues[name] = value;
    }
    return formValues;
}

/**
 * Checks if all questions in a form have been answered.
 *
 * @param {object} formValues - The values of the form.
 * @param {object} question - The question object to check.
 * @returns {boolean} - True if all questions have been answered, false otherwise.
 */
export const questionsAnswered = (formValues, question) => {
    const questionValue = formValues[question.name];

    // If the question has not been answered, return false
    if (!questionValue) return false

    const selectedOption = question?.options ? question.options?.find(option => option.value === questionValue) : null;
    const followUpQuestion = selectedOption ? selectedOption.followUpQuestions?.[0] : null;
    const followUpQuestionName = followUpQuestion?.name;

    if (followUpQuestion?.type === 'text') {
        return formValues[followUpQuestionName] !== '';
    }

    const validOptions = followUpQuestion ? followUpQuestion?.options?.map(option => option.value) ?? null : [];

    // If the question has a follow up question and the question has been answered, check if the follow up question has been answered
    if (validOptions?.length === 0) return true;
    return validOptions.includes(formValues[followUpQuestionName])
}

export const getUserRole = (formValues, question) => {
    const questionValue = formValues[question.name];

    const selectedOption = question?.options ? question.options?.find(option => option.value === questionValue) : null;
    const followUpQuestion = selectedOption ? selectedOption.followUpQuestions?.[0] : null;
    const followUpQuestionName = followUpQuestion?.name;

    if (selectedOption && !followUpQuestion) {
        return selectedOption?.value
    }

    console.log({ formValues, question, selectedOption, followUpQuestionName, valueOfFollowup: formValues[followUpQuestionName] });
    if (followUpQuestionName) {
        return formValues[followUpQuestionName]
    }
    return null;
}




