import { FormControl } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useUser } from '../hooks/useUser';
import QuestionField from './QuestionField';

const QuestionAndFollowup = ({ question, sendData, isErrorForm, setIsErrorForm }) => {
    const [followUpQuestion, setFollowUpQuestion] = useState(null);
    const { genderize } = useUser();
    const { id, text, type, options, name } = question;

    const genderizedText = genderize(text);
    const genderizeOptions = options && options.map(({ label, ...rest }) => {
        return { label: genderize(label), ...rest }
    });

    useEffect(() => {
        setFollowUpQuestion(null)
    }, [question])

    // @TODO - setup default values in question
    // @TODO - if field is gender get gender saved in user

    const receiveData = (selectedValue) => {
        setIsErrorForm(false);
        if (genderizeOptions) {
            const option = genderizeOptions.find(({ value }) => value === selectedValue);
            setFollowUpQuestion(option?.followUpQuestions?.[0] || null);

            // clear the answer to any previously selected follow up questions

        }
    }

    return (
        <>
            <FormControl fullWidth>
                <QuestionField
                    id={id}
                    name={name}
                    label={genderizedText}
                    type={type}
                    options={genderizeOptions}
                    value={null ?? ''}
                    sendData={receiveData}
                    isErrorForm={isErrorForm}
                />
                {followUpQuestion &&
                    <QuestionAndFollowup
                        question={followUpQuestion}
                        sendData={receiveData}
                        isErrorForm={isErrorForm}
                        setIsErrorForm={setIsErrorForm}
                    />}
            </FormControl>
        </>
    );
};

export default QuestionAndFollowup;