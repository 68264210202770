import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#d23439',
        },
        secondary: {
            main: '#f2f2f2',
        }
    },
    typography: {
        fontSize: 16,
        h1: {
            fontFamily: 'poetsenone',
            fontSize: '3rem',
            // color: '#404042'
            color: '#505052'
        },
        h2: {
            fontFamily: 'poetsenone',
            fontSize: '1.8rem',
            color: '#404042'
        },
        h3: {
            fontSize: '1.6rem',
            color: '#505052'
        },
        p: {
            fontFamily: 'Open Sans, Helvetica, Arial'
        }
    },
});