export const questions = [
    {
        id: '1', text: '¿Cual es tu ról en la iglesia?', name: 'iglesia-role',
        type: 'select',
        options: [
            {
                label: 'Pastor/ líder', value: 'pastor',
                followUpQuestions: [{
                    id: '1.1', text: 'Sobre tu ról?', name: 'pastor-role',
                    type: 'select',
                    options: [
                        { label: 'Pastor', value: 'pastor-pastor' },
                        { label: 'Líder de jovenes', value: 'pastor-jovenes' },
                        { label: 'Maestro de niños', value: 'pastor-ninos' },
                        { label: 'Otro líder', value: 'pastor-otro' }
                    ]
                }]
            },
            {
                id: 2, label: 'Candidato/ Misionero', value: 'misionero-candidato',
                followUpQuestions: [{
                    id: '2.1', text: '¿En qué etapa te encuentras?', name: 'misionero-etapa',
                    type: 'select',
                    options: [
                        { label: 'En descubrir mi llamado', value: 'misionero-llamado' },
                        { label: 'En preparación', value: 'misionero-entrenamiento' },
                        { label: 'En el campo', value: 'misionero-campo' }
                    ]
                }]
            },
            {
                id: 3, label: 'Movilizador', value: 'movilizador',
                followUpQuestions: [{
                    id: '3.1', text: '¿Qué área te interesa más?', name: 'movilizar-area', type: 'select',
                    options: [
                        { label: 'Movilizar a la iglesia en general', value: 'movilizar-general' },
                        { label: 'Movilizar a las damas', value: 'movilizar-damas' },
                        { label: 'Movilizar a los pastores', value: 'movilizar-pastores' },
                        { label: 'Movilizar a los jóvenes/adolescentes/niños', value: 'movilizar-jovenes-ninos' },
                        { label: 'Movilizar a los candidatos para el campo misionero', value: 'movilizar-candidatos' }
                    ]
                }]
            },
            {
                id: 4, label: 'Encargado de cuidado integral', value: 'cuidado-integral',
                followUpQuestions: [{
                    id: '4.1', text: '¿Qué área te interesa más?', name: 'cuidado-integral-area', type: 'select',
                    options: [
                        { label: 'Cuidado integral en general', value: 'cuidado-general' },
                        { label: 'Autocuidado y resistencia del obrero', value: 'cuidado-autocuidado' },
                        { label: 'Regresando a casa', value: 'cuidado-regresando' },
                    ]
                }]
            },
            {
                label: 'Apoyador / orador de la Misión', value: 'orador'
            }
        ]
    },
    {
        id: '2', text: '¿Cual es tu nombre preferido?', name: 'name',
        type: 'text'
    },
];
