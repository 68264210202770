import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import genderizeNoun from '../utilities/genderizeNoun';
import { useLocation } from 'react-router-dom';

// Create a context to store user data
const UserContext = createContext();

// Custom hook to access the user data from the context
export function useUser() {
    return useContext(UserContext);
}

// Provider component to wrap the app and provide the user data
export function UserProvider({ children }) {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Function to update the user data
    const updateUser = useCallback((userData) => {
        setUser(userData);
    }, []);

    const genderize = (question) => {
        if (!question || question === '') return question
        return question.split(' ')
            .map((word) => genderizeNoun(word, user?.gender))
            .join(' ')
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('mid');

        // Set default values if no id is provided
        if (!id) {
            setUser({ id: null, name: null, gender: null })
            return;
        }

        localStorage.setItem('mid', id);
        setIsLoading(true);
        getUserData(id)
            .then(data => {
                console.log('data', data);
                updateUser({ id, ...data });
            })
            .finally(() => {
                setIsLoading(false);
            });
        // @TODO - fetch localStorage data if no id is provided
    }, [location.search, updateUser]);

    return (
        <UserContext.Provider value={{ user, isLoading, updateUser, genderize }}>
            {children}
        </UserContext.Provider>
    );
}

export const getUserData = async (id) => {
    const dataFetched = await fetch(`https://gyychwpn1l.execute-api.us-east-1.amazonaws.com/Production/profile?id=${id}-name`);
    // if response is not ok, throw an error
    if (!dataFetched.ok) {
        throw new Error('Network response was not ok');
    }
    const response = await dataFetched.json();
    return response.data;
}
