import { Box, Button, FormHelperText, Typography } from '@mui/material';
import React, { useState } from 'react';
import QuestionAndFollowup from './QuestionAndFollowup';
import RegisterUser from './RegisterUser';
import { useUser } from '../hooks/useUser';
import { getUserRole, getFormValues, questionsAnswered } from '../utilities/utils';
import WarningIcon from '@mui/icons-material/Warning';
import sendResponses from '../utilities/sendResponses';

const Questionnaire = ({ questions, userName, userId, handleFinish }) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userRole, setUserRole] = useState(null);
    const [isErrorForm, setIsErrorForm] = useState(false);
    const { user, updateUser } = useUser();

    const addToUserData = (formValues) => {
        Object.keys(user).forEach((key) => {
            if (formValues[key]) {
                user[key] = formValues[key];
            }
        })
        updateUser(user);
    }

    const handleNext = (event) => {
        event.preventDefault();
        const formValues = getFormValues();
        if (!questionsAnswered(formValues, questions[currentQuestion])) {
            setIsErrorForm(true);
            // return;  TEMPORARILY DISABLED
        }
        setIsErrorForm(false);
        const questionUserRole = questions[0]
        const answer = getUserRole(formValues, questionUserRole)
        if (answer) setUserRole(answer);

        sendResponses(userId, formValues);
        if (currentQuestion + 1 >= questions.length) {
            console.log('finalAnswer', answer, userRole);
            handleFinish(answer ?? userRole)
        } else {
            setCurrentQuestion((prevQuestion) => prevQuestion + 1);
        }
    };

    const { id, name } = questions[currentQuestion] ?? {};
    const isLast = currentQuestion + 1 === questions.length

    if (id === undefined || name === undefined) {
        return <RegisterUser />
    }

    return (
        <Box sx={{ pt: 4, width: '100%' }}>
            {userName && <Typography variant='h5' sx={{ my: 2 }}>Hola {userName} </Typography>}
            <form>
                <Box sx={{ minHeight: '180px' }}>
                    <QuestionAndFollowup
                        question={questions[currentQuestion]}
                        sendData={null}
                        isErrorForm={isErrorForm}
                        setIsErrorForm={setIsErrorForm}
                    />
                    {isErrorForm &&
                        <FormHelperText sx={{ color: 'error.main', display: 'flex', alignItems: 'center' }}>
                            <WarningIcon sx={{ verticalAlign: 'bottom', mr: 1 }} /> Por favor, llenar los campos
                        </FormHelperText>
                    }
                </Box>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleNext}
                    sx={{ my: 4, py: 2 }}
                >
                    {isLast ? 'Mostrar Recursos' : 'Siguiente'}
                </Button>
            </form>
        </Box>
    );
};

export default Questionnaire;

