const sendResponses = (id, responses) => {
    console.log('POST', id, responses);
    if (!id) return null;

    return fetch(`https://gyychwpn1l.execute-api.us-east-1.amazonaws.com/Production/profile`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id, ...responses })
    })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

export default sendResponses