import { InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'

const QuestionField = ({ id, name, label, type, options, defaultValue, sendData, isErrorForm }) => {

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);
        if (sendData) {
            sendData(selectedValue);
        }
    }

    const style = { fontSize: '1rem' }

    switch (type) {
        case 'text':
            return (
                <>
                    <TextField
                        id={id}
                        label={label}
                        name={name ?? `question-${id}`}
                        fullWidth
                        error={isErrorForm && selectedValue === ''}
                        required
                    />
                </>
            )
        case 'select':
            return (
                <>
                    <InputLabel id="select-label" sx={selectedValue ? { pt: 0 } : { pt: 2 }}>{label}</InputLabel>
                    <Select
                        labelId="select-label"
                        id={id}
                        name={name ?? `question-${id}`}
                        fullWidth
                        onChange={handleChange}
                        sx={{ my: 2, ...style }}
                        value={selectedValue ?? ''}
                        error={isErrorForm && selectedValue === ''}
                        required
                    >
                        {options && options.map(({ label, value }) => (
                            <MenuItem key={value} value={value} sx={style}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            )
        default:
            return null; // Add a default case to handle other types
    }
}

export default QuestionField;