import React from 'react'

const RegisterUser = () => {

    // const { updateUser } = useUser()
    // Here we get the users email, fetch the user.
    // If the user is already registered, we update the user data.
    // If the user is not registered, we create a new user.
    // We then update the user data in the context.
    // This will automatically redirect the user to the first question in the survey.
    return (
        <div>Register User</div>
    )
}

export default RegisterUser