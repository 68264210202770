import { Container, Grid, Paper, Typography } from '@mui/material';
import Survey from './components/Survey';
import { ThemeProvider } from '@mui/material/styles';
import logoMovilicemos from './assets/logo.png';
import logoSIM from './assets/SIMLA.png';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from './theme';
import { UserProvider } from './hooks/useUser';
import useDatadog from 'react-datadog';

function App() {
  useDatadog({
    applicationId: '28522739-f2b5-43e3-bc7c-b36757d3a24d',
    clientToken: 'pub5491ee3fe29949bff69f1378cf2e89cd',
    site: 'us5.datadoghq.com',
    service: 'mi-movilicemos',
    env: process.env.NODE_ENV ?? 'production',
    sessionReplayRecording: true,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    version: '0.1.0'
  });

  console.log('NODE_ENV', process.env.NODE_ENV);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Container maxWidth='sm' sx={{ my: 2 }}>
          <Paper elevation={3} sx={{
            p: 2, my: 2, display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // height: '80vh'
          }}>
            <Grid container>
              <Grid item xs={3}>
                <img width='100%' src={logoMovilicemos} alt="Movilicemos logo" />
              </Grid>
              <Grid item xs={9}>
                <Typography variant='h2' sx={{ ml: 2 }}>Mi Movilicemos</Typography>
              </Grid>
            </Grid>
            <UserProvider>
              <Survey />
            </UserProvider>
            <Grid container>
              <Grid item xs={9}>

              </Grid>
              <Grid item xs={3} sx={{ pl: 1 }}>
                <img width='100%' src={logoSIM} alt="SIM Latin America" />
              </Grid>
            </Grid>
          </Paper >
        </Container >
      </Router>
    </ThemeProvider>
  );
}

export default App;
