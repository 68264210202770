// convert candidate to candidato or candidata based on the gender
const genderizeNoun = (word, gender) => {
    const lookupTable = {
        candidato: {
            masculine: 'candidato',
            f: 'candidata'
        },
        misionero: {
            masculine: 'misionero',
            f: 'misionera'
        },
        pastor: {
            masculine: 'pastor',
            f: 'pastora'
        },
        anciano: {
            masculine: 'anciano',
            f: 'anciana'
        },
        casado: {
            masculine: 'casado',
            f: 'casada'
        },
        soltero: {
            masculine: 'soltero',
            f: 'soltera'
        },
        diácono: {
            masculine: 'diácono',
            f: 'diaconisa'
        }
    };

    const lowerCaseWord = word.toLowerCase();
    const lookupWord = lookupTable[lowerCaseWord] ? lowerCaseWord : word;

    if (lookupTable[lookupWord]) {
        const lookupGender = lookupTable[lookupWord][gender];
        if (lookupGender) {
            if (word === word.toUpperCase()) {
                return lookupGender.toUpperCase();
            } else if (word === word.toLowerCase()) {
                return lookupGender.toLowerCase();
            } else {
                return lookupGender.charAt(0).toUpperCase() + lookupGender.slice(1);
            }
        }
    }

    return word;
}

export default genderizeNoun;