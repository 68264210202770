import { CircularProgress } from '@mui/material'
import React from 'react'

const LoadingPage = () => {
    return (
        <>
            <h3>Gracias por tus respuestas.</h3>
            <p>Ahora estamos reuniendo algunos recursos relevantes.</p>
            <CircularProgress sx={{ my: 10 }} />;
        </>
    )
}

export default LoadingPage